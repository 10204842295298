import { Season } from "./shared/models/Season";
import { SATURDAY, SUNDAY, MIDWEEK } from "./tippelaget.config";
export var ALL_GAMES = new Season("Alle kamper ever", [SATURDAY.id, SUNDAY.id, MIDWEEK.id], 0, 999999);
export var SATURDAY_21 = new Season("Lørdagskamper 2021", [SATURDAY.id], 46000, 50000);
export var SUMMER_21 = new Season("Lørdagskamper 2021", [SATURDAY.id], 50000, 50990);
export var PL_20_21 = new Season("Premier League 20/21", [SATURDAY.id, SUNDAY.id, MIDWEEK.id], 43848, 49211);
export var PL_21_22 = new Season("Premier League 21/22", [SATURDAY.id], 50991, 55492);
export var PL_22_23 = new Season("Premier League 22/23", [SATURDAY.id], 56872, 60842);
export var EL_20 = new Season("Eliteserien 2020", [SATURDAY.id, SUNDAY.id, MIDWEEK.id], 40000, 46000);
export var EL_21 = new Season("Eliteserien 2021", [SATURDAY.id, SUNDAY.id, MIDWEEK.id], 48000, 56000);
export var EM_21 = new Season("Europamesterskap 2021", [SATURDAY.id, SUNDAY.id, MIDWEEK.id], 48000, 50000);
export default [ALL_GAMES, SATURDAY_21, PL_20_21, PL_21_22, EL_20, EL_21, EM_21];

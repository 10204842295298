import { uuid } from "../utils/uuid";
var Season = /** @class */ (function () {
    function Season(name, gameTypeIds, startGameId, endGameId) {
        this.id = uuid();
        this.name = name;
        this.gameTypeIds = gameTypeIds;
        this.startGameId = startGameId;
        this.endGameId = endGameId;
    }
    return Season;
}());
export { Season };

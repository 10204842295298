import { ref } from "vue";
import allSeasons, { PL_22_23 } from "../../../tippelaget.seasons.config";
var seasons = ref(allSeasons);
export default (function () {
    var currentSeason = ref(PL_22_23);
    var setSeason = function (season) {
        currentSeason.value = season;
        console.log("Season set: " + currentSeason.value.name);
    };
    return {
        currentSeason: currentSeason,
        seasons: seasons,
        setSeason: setSeason,
    };
});
